interface CloseIconProps {
  width?: number;
  height?: number;
  stroke?: string;
}
export const CloseIcon = ({ width = 10, height = 10, stroke = "white" }: CloseIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00995 1.01007L9.03009 9.03021M9.03009 1.01007L1.00995 9.03021"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
