import {
  Box,
  Button,
  List,
  ListItem,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { CloseIcon } from "../../svg";
import {
  BANKI_RU_REQUIREMENTS,
  BANKI_RU_STOP_LIST,
} from "../../forms/ReviewForm/ReviewForm.constants";

interface ModalBankiRuProps {
  isOpen: boolean;
  onClose: () => void;
}

export function ModalBankiRu({ isOpen, onClose }: ModalBankiRuProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay style={{ background: "#C9C9C94D", backdropFilter: "blur(6px)" }} />
      <ModalContent
        className="relative mx-8 px-5 py-6"
        style={{ marginTop: "22vh", borderRadius: "24px" }}
      >
        <button type="button" className="absolute top-4 right-4 outline-0" onClick={onClose}>
          <CloseIcon stroke="#B7B7B7" width={20} height={20} />
        </button>
        <ModalHeader className="text-center" color="text.heading">
          Что должно быть в&nbsp;отзыве Банки.ру:
        </ModalHeader>
        <List className="flex flex-col gap-[10px]">
          {BANKI_RU_REQUIREMENTS.map((requirement, index) => (
            <ListItem key={requirement} className="flex items-center gap-[8px]">
              <Box
                className="flex justify-center items-center min-w-[25px] h-[25px] border border-[#1D6000] rounded-full text-xs"
                color="marker.green"
              >
                {index + 1}
              </Box>
              <Text fontSize={14}>{requirement}</Text>
            </ListItem>
          ))}
          {BANKI_RU_STOP_LIST.map((stopItem) => (
            <ListItem key={stopItem} className="flex items-center gap-[8px]">
              <Box className="flex justify-center items-center min-w-[25px] h-[25px] border border-[#F03226] rounded-full text-xs">
                <CloseIcon stroke="#F03226" />
              </Box>
              <Text fontSize={14}>{stopItem}</Text>
            </ListItem>
          ))}
        </List>
      </ModalContent>
      <Button onClick={onClose}>X</Button>
    </Modal>
  );
}
