import { z } from "zod";
import { SelectOption, SelectOptionWithIcon } from "../../../../../schemas";

export const FirstFormSchema = z.object({
  // name: RequiredTextField,
  city: SelectOption,
  cityPoint: SelectOption,
  app: SelectOptionWithIcon,
  agree: z.literal(true),
});
