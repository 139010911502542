import { useBoolean } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import { SelectOption } from "../../../../../types";
import { transformPhoneNumber } from "../../../../../utils";
import { getBanks, getCanPayment } from "../../ReviewForm.api";
import { ThirdFormSchema } from "./ThirdFormStep.schema";
import { ThirdFormStepProps } from "./ThirdFormStep.types";

const DEBOUNCE_DELAY = 500;
const PHONE_NUMBER_LENGTH = 12;

export const useThirdFormStep = ({
  onBack,
  onChange,
  defaultValues,
  formData,
}: Partial<ThirdFormStepProps>) => {
  const form = useForm({
    resolver: zodResolver(ThirdFormSchema),
    mode: "onSubmit",
  });

  const {
    getValues,
    watch,
    formState: { isSubmitting, errors },
    setError,
    clearErrors,
  } = form;

  const [isBanksVisible, { off: setBanksInvisible, on: setBanksVisible }] = useBoolean();
  const [banks, setBanks] = useState<SelectOption[]>([]);
  const [isLoading, setLoading] = useState<boolean>();
  const [isValid, setValid] = useState(false);

  const debounced = useDebouncedCallback(async (params: any) => {
    const paymentStatus = await getCanPayment(params);
    if (!(typeof paymentStatus === "boolean")) {
      setError("phone", {
        message: paymentStatus.message,
      });
    } else {
      clearErrors("phone");
    }

    setLoading(false);
  }, DEBOUNCE_DELAY);

  const variant = isValid ? "primary" : "secondary";

  const handleBack = () => {
    onChange?.(getValues());
    onBack?.();
  };

  // Управляет состоянием поля "bank" при взаимодействии с полем "refill".
  useEffect(() => {
    const { value } = form.getValues("refill") || {};

    if (value && value === "СБП") {
      setBanksVisible();
    } else {
      setBanksInvisible();
      form.setValue("bank", null);
    }
  }, [form.watch("refill")]);

  // Получает и устанавливает данные для банков.
  useEffect(() => {
    getBanks().then(setBanks);
  }, []);

  // Восстанавливает введённые значения при ререндере.
  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  // Принудительная валидация формы.
  useEffect(() => {
    try {
      ThirdFormSchema.parse(getValues());
      setValid(!Boolean(errors.phone));
    } catch (e) {
      setValid(false);
    }
  }, [watch()]);

  useEffect(() => {
    const phoneNumber = form.getValues("phone");
    const transformedPhone = transformPhoneNumber(phoneNumber);

    if (transformedPhone && transformedPhone.length === PHONE_NUMBER_LENGTH) {
      setLoading(true);

      debounced({
        source_id: formData.app.value,
        phone: transformedPhone,
      });
    }
  }, [form.watch("phone")]);

  return {
    form,
    handleBack,
    isBanksVisible,
    banks,
    isSubmitting,
    isValid,
    variant,
    isLoading,
  };
};
