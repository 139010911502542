import { Button, Image, Text, VStack } from "@chakra-ui/react";
import { FormSuccessProps } from "../SuccessState/FormSuccess.types";

import SuccessEmoji from "../../../../../assets/FailureEmoji.png";
import { WhatsAppButton } from "../SuccessState/WhatsAppButton/WhatsAppButton";

export const FormFailure = ({ onYes }: Pick<FormSuccessProps, "onYes">) => (
  <VStack gap="20px" w="full" height="100%" flexGrow="1" justifyContent="center">
    <Image src={SuccessEmoji} width="175px" height="175px" />
    <VStack w="full" gap="16px">
      <Text fontSize="20px" fontWeight="semibold" color="text.heading">
        Возникла ошибка
      </Text>
      <Text fontSize="16px" textAlign="center" color="text.body">
        Возможно превышен лимит по&nbsp;загрузке отзыва.
      </Text>
      <Button onClick={onYes} w="255px">
        Проверить новый отзыв
      </Button>
    </VStack>

    <VStack gap="10px" mt="30px">
      <Text color="text.body">Возникли проблемы?</Text>
      <WhatsAppButton />
    </VStack>
  </VStack>
);
