import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Image,
  Link,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";

import SuccessEmoji from "../../../../../assets/SuccessEmoji.png";
import { ChevronIcon } from "../../../../svg";
import { CrossIcon } from "../../../../svg/CrossIcon";

import { HTMLText } from "./FormSuccess.styles";

import { FormSuccessProps } from "./FormSuccess.types";

import { WhatsAppButton } from "./WhatsAppButton/WhatsAppButton";
import { useState } from "react";
import { ModalBankiRu } from "../../../../common/ModalBankiRu/ModalBankiRu";

export const FormSuccess = ({ platformList, onYes }: FormSuccessProps) => {
  const [showPopup, setShowPopup] = useState(false);
  const bankiRuPlatform = platformList.find((platform) => platform.id === 2);

  const platformButtonHandler = (e: React.MouseEvent, platformId: number) => {
    if (platformId === bankiRuPlatform?.id) {
      e.preventDefault();
      setShowPopup(true);
    }
  };

  const closeModalHandler = () => {
    setShowPopup(false);
    window.open(bankiRuPlatform?.link, "_blank");
  };

  return (
    <VStack gap="16px">
      <Image src={SuccessEmoji} width="134px" height="134px" />
      <VStack w="full" gap="24px">
        <VStack w="full" gap="16px">
          <Text fontSize="20px" color="text.heading" fontWeight="600">
            Ваш отзыв на проверке!
          </Text>
          <Text fontSize="14px" color="text.body" textAlign="center" whiteSpace="break-spaces">
            Вознаграждение будет направлено в течение суток. Оставьте этот же отзыв на других
            площадках, если Вы еще не сделали этого, и получите до 5.000₽.
          </Text>
          <Text fontSize="14px" color="text.body" textAlign="center" whiteSpace="break-spaces">
            Для зачета отзыва на Банки.ру Вам могут позвонить с +7 (495) 665-52-55 или +7 (495)
            665-52-54. Будьте на связи!
          </Text>
        </VStack>
        <Button fontSize="14px" fontWeight="400" onClick={onYes}>
          Проверить новый отзыв
        </Button>
        <List w="full" spacing="18px">
          {platformList.map((item) => (
            <ListItem>
              <VStack w="full" gap="10px" alignItems="initial">
                <Link href={item.link} variant="outlined" borderRadius="24px" isExternal>
                  <Button
                    onClick={(e) => platformButtonHandler(e, item.id)}
                    variant="external"
                    borderRadius="24px"
                    gap="10px"
                  >
                    <Image src={item.button_icon} width="22px" height="22px" />
                    <Text fontSize="20px">{item.button_text}</Text>
                  </Button>
                </Link>
                <Accordion allowMultiple>
                  <AccordionItem borderTop="none">
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton padding="2.5px 20px" _hover={{ background: "none" }}>
                          <Box
                            as="span"
                            flex="1"
                            textAlign="left"
                            fontSize="12px"
                            color="text.body"
                          >
                            {item.title}
                          </Box>
                          {isExpanded ? (
                            <CrossIcon />
                          ) : (
                            <ChevronIcon stroke="#595959" width="19" height="19" />
                          )}
                        </AccordionButton>
                        <AccordionPanel pb={2.5}>
                          <HTMLText
                            dangerouslySetInnerHTML={{ __html: item.description }}
                            fontSize="12px"
                          />
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                </Accordion>
              </VStack>
            </ListItem>
          ))}
        </List>
        <WhatsAppButton />
      </VStack>
      <ModalBankiRu isOpen={showPopup} onClose={closeModalHandler} />
    </VStack>
  );
};
